import { GeppettoApiResponse } from '@/shared/services/api-client';

import ApiClientError, { RequestError } from './ApiClientError';

/**
 * The HyperText Transfer Protocol (HTTP) 401 Unauthorized response status code
 * indicates that the client request has not been completed because it lacks valid
 * authentication credentials for the requested resource.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
 */
export default class NotAuthenticatedError<
  TD = never,
  TR extends GeppettoApiResponse<TD> = GeppettoApiResponse<TD>,
  TError extends RequestError<TR> = RequestError<TR>,
> extends ApiClientError<TD, TR, TError> {
  public readonly name = 'NotAuthenticatedError';
  public isGeppettoAuth: boolean;

  constructor(error: TError) {
    super(error);

    this.isGeppettoAuth = this.sameOrigin;
  }
}
