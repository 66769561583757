import { initGeppettoShared } from '@/shared/init';

import app, { geppetto } from './config';

initGeppettoShared({
  name: app.name,
  version: app.version,
  environment: app.environment,
  dev: app.dev,
  debug: app.debug,
  ...geppetto,
});
