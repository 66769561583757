import ApiClientError from './ApiClientError';

/**
 * The HyperText Transfer Protocol (HTTP) 422 Unprocessable Content response status code
 * indicates that the server understands the content type of the request entity, and the
 * syntax of the request entity is correct, but it was unable to process the contained
 * instructions.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/422
 */
export default class ValidationError extends ApiClientError {
  public readonly name = 'ValidationError';
}
