import { type GeppettoConfigOptions, getSessionConfig } from '@/shared/init';
import { CancelledError, NetworkError } from '@/shared/services/api-client/errors';

const app = {
  name: import.meta.env.SERVICE_NAME || 'Sender UI',
  version: __VITE_GITSHA,
  environment: import.meta.env.ENV || 'local',
  deploymentBranch: import.meta.env.DEPLOYMENT_BRANCH || 'local',
  debug: import.meta.env.NODE_ENV !== 'production',
  dev: import.meta.env.MODE === 'dev' || import.meta.env.MODE === 'development',
  appTitle: import.meta.env.VITE_APP_TITLE || 'GEP Sender UI',

  defaultRoute: 'consignment.readyToManifest',
  resourcesPerPage: 30,
  maxSiteIdParams: 30,
  baseUrl: import.meta.env.BASE_URL,
  minChromeVersion: 92, // 92 added support for array.at

  assetDomain: import.meta.env.ASSET_DOMAIN,
};

export const session = getSessionConfig();

export const auth = {
  checkInterval: 60,
};

export const rum = {
  datadog: import.meta.env.RUM_DATADOG === 'true' && !session.isCypress,
};

export const appcues = {
  // TODO - during trial synths are nice event generators
  // enabled: import.meta.env.APPCUES_ENABLED === 'true' && !session.isCypress && !session.isSynthetic,
  enabled: import.meta.env.APPCUES_ENABLED === 'true' && !session.isCypress,
  account_id: import.meta.env.APPCUES_ACCOUNT_ID,
};

export const datadog = {
  rumAppID: import.meta.env.DATADOG_RUM_APP_ID,
  rumClientToken: import.meta.env.DATADOG_RUM_CLIENT_TOKEN,
};

export const addressFinder = {
  key: import.meta.env.ADDRESS_FINDER_KEY,
  baseUrl: 'https://api.addressfinder.io/api/au',
  searchDelay: 50, // default keypress interval from the official Widget.js
};

export const fullStory = {
  enabled: import.meta.env.FULLSTORY_ENABLED === 'true' && !session.isCypress && !session.isSynthetic,
  orgId: import.meta.env.FULLSTORY_ORG_ID,
};

if (fullStory.enabled && app.debug && import.meta.env.NODE_ENV !== 'test') {
  app.debug = false;
  // eslint-disable-next-line no-console
  console.warn('FullStory and app.debug configs are incompatible, app.debug has been disabled');
  // `app.debug` enables vm logging in error handling
  // FullStory serializes objects in console.* method, resulting in an infinite loop
}

// geppetto shared settings
export const geppetto: Partial<GeppettoConfigOptions> = {
  logger: {
    defaultLevel: import.meta.env.LOGGER_DEFAULT_LEVEL || 'error',
    datadog: import.meta.env.LOGGER_DATADOG === 'true' && !session.isCypress,
    console: import.meta.env.LOGGER_CONSOLE === 'true',
    ignoreEventMarker: '🤫💾🐶',
  },
  datadogLogs: {
    clientToken: import.meta.env.DATADOG_LOG_CLIENT_TOKEN,
  },
  errorMonitoring: {
    ignoreErrorsCausedBy: [NetworkError, CancelledError],
  },
  rollbar: {
    accessToken: import.meta.env.ROLLBAR_ACCESS_TOKEN,
    enabled: !!import.meta.env.ROLLBAR_ACCESS_TOKEN && !session.isCypress,
  },
  fullStory,
};

export default app;
