import ApiClientError from './ApiClientError';

/**
 * The HTTP 403 Forbidden response status code indicates that the server understands the request
 * but refuses to authorize it.
 * This status is similar to 401, but for the 403 Forbidden status code, re-authenticating makes
 * no difference. The access is tied to the application logic, such as insufficient rights to a
 * resource.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/403
 */
export default class PermissionDeniedError extends ApiClientError {
  public name = 'PermissionDeniedError';
}
