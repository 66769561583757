import Logger from '@/shared/logger';

export interface GeppettoConfigOptions {
  name: string;
  environment: string;
  version: string;
  dev: boolean;
  debug: boolean;
  logger?: {
    defaultLevel?: 'debug' | 'error' | 'info' | 'warn'; // datadog StatusType
    datadog?: boolean;
    console?: boolean;
    ignoreEventMarker?: string;
  };
  datadogLogs?: {
    clientToken: string;
  };
  errorMonitoring?: {
    ignoreErrorsCausedBy?: ErrorType[];
  };
  rollbar?: {
    enabled: boolean;
    accessToken?: string;
  };
  fullStory?: {
    enabled: boolean;
    orgId: string;
  };
}

interface GeppettoConfig extends GeppettoConfigOptions {
  session: {
    isTest: boolean;
    isCypress: boolean;
    isSynthetic: boolean;
  };
}

declare global {
  interface Window {
    logger: Logger;
    gep: GeppettoConfig;
    Cypress: unknown;
  }
}

let geppettoConfig: GeppettoConfig | undefined;

const session = {
  isTest: import.meta.env.MODE === 'test',
  isCypress: !!window.Cypress,
  isSynthetic: window.navigator.userAgent.includes('DatadogSynthetics'),
};

/**
 * Provide configuration for common Geppetto utilities, such as logger and error monitoring.
 *
 * Note: this method **must** be called with configuration values before any modules are loaded.
 *   This can be accomplished by putting a call to initGeppettoShared in its own module and
 *   importing it at the top of your main entry point.
 *   However, be careful when using bundlers like rollup, which may inline the module, moving it
 *   below other import declarations. Rollup can be configured in multiple ways to prevent a
 *   module from being inlined, such as `preserveModules`, `input`, `output.manualChunks`.
 *
 * @param config
 */
export function initGeppettoShared(config: GeppettoConfigOptions) {
  if (window.gep) {
    throw new Error('reinitialising geppetto config not supported at this time');
  }

  geppettoConfig = {
    session,
    ...config,
  };

  window.gep = geppettoConfig;

  if (geppettoConfig.dev) {
    /* eslint-disable no-console */
    console.groupCollapsed('config');
    console.table(geppettoConfig);
    console.groupEnd();
    /* eslint-enable no-console */
  }

  if (!geppettoConfig.logger || (geppettoConfig.logger.datadog && !geppettoConfig.datadogLogs)) {
    throw new Error('cannot initGeppettoShared without logger config');
  }

  // instantiate logger and make it globally available
  Object.defineProperty(window, 'logger', {
    value: new Logger({
      app: geppettoConfig,
      datadog: geppettoConfig.datadogLogs,
      logger: geppettoConfig.logger,
    }),
    enumerable: true,
    writable: false,
  });
}

export function getConfig() {
  if (!geppettoConfig) throw new Error('geppetto config has not been initialised');
  return geppettoConfig;
}

export function getSessionConfig() {
  return session;
}
