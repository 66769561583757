/**
 * Mutate event to remove sensitive details, or return false to stop the event from
 * being sent.
 * @return void|false
 */
const scrubSensitiveData = event => {
  const poisonStrings = ['authorization', 'fullname'];
  if (poisonStrings.some(danger => event.message.toLowerCase().includes(danger))) {
    // eslint-disable-next-line no-console
    console.warn('Detected log event containing sensitive data', event);
    return false;
  }

  if (event.error) {
    // logger and source errors seem fairly sanitised so far!
  }
};

export default scrubSensitiveData;
