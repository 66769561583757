import { LogsEvent } from '@datadog/browser-logs';

/**
 * Return true on network requests that are unsuccessful for known reasons, ie if the
 * request was intentionally aborted. This will help us prevent them from being logged.
 * @return void|true
 */
const expectedNetworkError = (event: LogsEvent): boolean => {
  // aborted requests should not be logged as errors
  // unfortunately this also filters out requests that failed on the network, because
  // the LogsEvent provided by beforeSend doesn't have any detail about whether an xhr
  // was aborted or not.
  // @see https://github.com/DataDog/browser-sdk/issues/362#issuecomment-1683460565
  if (event.error && event.http?.status_code === 0) {
    return true;
  }
  return false;
};

export default expectedNetworkError;
