import { Method } from 'axios';

import { combineURLs, isSameOriginUrl } from '@/shared/services/api-client/helpers/url';
import { ApiClientResponse, GeppettoApiResponse, JSONAPIError } from '@/shared/services/api-client/types';

interface RequestConfig {
  method?: Method | string;
  baseURL?: string;
  url?: string;
}

// derived from AxiosError but decoupled
export interface RequestError<TResponse> extends Error {
  config?: RequestConfig;
  response?: ApiClientResponse<TResponse>;
}

export default class ApiClientError<
    TD = unknown,
    TR extends GeppettoApiResponse<TD> = GeppettoApiResponse<TD>,
    TError extends RequestError<TR> = RequestError<TR>,
  >
  extends Error
  implements RequestError<TR>
{
  public config?: RequestConfig;
  public response?: ApiClientResponse<TR>;

  public status: number | null;
  public sameOrigin: boolean;

  public kind: string;

  public apiErrors?: JSONAPIError[];
  public apiError?: JSONAPIError;

  public code?: string;

  public name = 'ApiClientError';

  constructor(error: TError) {
    super(error.message, { cause: error });

    // Maintains proper stack trace for where our error was thrown
    // Only works in v8 (node & Chrome)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    // mirror properties available from network errors so that an ApiClientError or extending class
    // can be constructed with an ApiClientError
    this.config = error.config;
    this.response = error.response;

    this.sameOrigin = isSameOriginUrl(combineURLs(error.config?.baseURL, error.config?.url));
    this.status = this.response?.status || null;
    this.kind = ApiClientError._errorKindFromResponse<TR>(this.response);
    if (this.response?.data?.errors) {
      // backend will return the error in an array of errors to cater for validation errors. For other kind of errors
      this.apiErrors = this.response.data.errors; // backend errors
      // we can assume the first will be the main error to check
      this.apiError = this.response.data.errors[0];
      this.code = this.apiError?.code; // backend error code
    }
  }

  static _errorKindFromResponse<TR>(response?: ApiClientResponse<TR>) {
    if (!response) {
      return 'NetworkError';
    }

    const statusErrors: Record<number | string, string> = {
      400: 'BadRequestError',
      401: 'NotAuthenticatedError',
      403: 'PermissionDeniedError',
      404: 'NotFoundError',
      409: 'DuplicateError',
      412: 'PreconditionFailedError',
      422: 'ValidationError',
      default: 'BackendError',
    };
    return statusErrors[response.status] || statusErrors.default;
  }
}
