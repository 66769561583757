import ApiClientError from './ApiClientError';

/**
 * A NetworkError is any error where a network request failed to result in a response.
 * Network errors could occur in many parts of the stack and transmission process, including:
 * - temporary or permanent loss of network connection
 * - dns resolution failure
 * - request aborted by a remote host
 * - connection abandoned by a remote host
 * - request timed out
 * - etc.
 */
export default class NetworkError extends ApiClientError {
  public name = 'NetworkError';
}
